import SalonandSpaTowels1 from "../Images/SpaTowel8.png";
import SalonandSpaTowels2 from "../Images/SpaTowel9.png";
import SalonandSpaTowels3 from "../Images/Salon And Spa4.png";
import SalonandSpaTowels4 from "../Images/spa towel 2.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function SalonandSpaTowels() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Wholesale Salon & Spa Towels, High Quality, Global Export" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={SalonandSpaTowels1} alt="Salon & Spa Towels" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={SalonandSpaTowels2} alt="Salon & Spa Towels" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={SalonandSpaTowels3} alt="Salon & Spa Towels" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={SalonandSpaTowels4} alt="Salon & Spa Towels" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Salon & Spa Towels</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International salon and spa towels are versatile essentials, perfect for use in hair salons, spas, facials, manicures, pedicures, and even as bar towels. Experience the convenience and quality of towels for all your salon and spa needs.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
