import ShortsandTrousers1 from "../Images/SHORTS 1.png";
import ShortsandTrousers2 from "../Images/SHORTS 2.png";
import ShortsandTrousers3 from "../Images/TROUSERS.png";
import ShortsandTrousers4 from "../Images/Trouser2.png";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function ShortsandTrousers() {
    return (
        <section className="py-5 mt-5" id="about">
             <Helmet>
                <meta name="keywords" content="Wholesale Shorts & Trousers, Comfortable & Stylish, Manufacturer & Exporter" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={ShortsandTrousers1} alt="Shorts & Trousers" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ShortsandTrousers2} alt="Shorts & Trousers" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ShortsandTrousers3} alt="Shorts & Trousers" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={ShortsandTrousers4} alt="Shorts & Trousers" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Shorts & Trousers</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International excels in designing and Outsourcing shorts and trousers that blend style, comfort, and functionality seamlessly. Elevate your wardrobe with our premium collection of shorts and trousers for every occasion.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}