import bathblanket1 from "../Images/bathblankets2.png";
import bathblanket2 from "../Images/Bath Blankets3.png";
import bathblanket3 from "../Images/Bath Blankets4.png";
import bathblanket4 from "../Images/Bath Blankets5.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function BathBlankets() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="description" content=""></meta>
                <meta name="keywords" content="Bath Blankets, Soft & Absorbent, Hotel Quality,Luxury Bath Wraps" />
            </Helmet>
            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={bathblanket1} alt="About Us" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                            <div className="col-3">
                                <img className="gallery-img" src={bathblanket2} alt="Bath Blanket" />
                            </div>
                            <div className="col-3">
                                <img className="gallery-img" src={bathblanket3} alt="Bath Blanket" />
                            </div>
                            <div className="col-3">
                                <img className="gallery-img" src={bathblanket4} alt="Bath Blanket" />
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Bath Blankets</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International bath blankets feature superior yarns and are napped on both sides, offering a soft flannel surface that minimizes pilling. Versatile and highly absorbent, they provide softness, warmth, and comfort for a variety of purposes.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

      
            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
