import React from "react";
import { Link } from "react-router-dom";
import "../css/Projects.css"
import { Helmet } from "react-helmet";
import HealthCare from "../Images/bed linens 2.png";
import Hospitality from "../Images/bedsheets3.png";
import Garments from "../Images/SHORTS 2.png";
import BedAndBath from "../Images/Duvet  Pillow Covers1.png";
import Commercial from "../Images/Industrial Apron2.png";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
}

export default function FeaturedProducts() {
    return (
        <section className="p-5">
            <Helmet>
                <meta name="keywords" content="Garments, Bed & Bath, Commercial, Hospitality, Ziftex International, specializes, Health Care, textile outsourcer, exporter, production facilities, Pakistan, top-tier textile products, U.S.A, Canada, Europe, Middle East, thriving, building, reliable products, featured products" />
            </Helmet>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="featured-project-wrapper">
                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="section-title-area">
                                        <h6 className="subtitle">FEATURED PRODUCTS</h6>
                                        <h2 className="font-weight-bold">We're thriving and building better, reliable products</h2>
                                        <div className="sectionUnderline-feature mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="">
                                        <p className="feature-text">Established in the year 2000, Ziftex International stands out as a renowned buying and outsourcing agency, proudly managing its operations in Pakistan. With a track record of exporting top-tier products to the U.S.A, Canada, Europe, and the Middle East, we have built reputation for excellence and reliability in the global market. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container page-top">
                <div className="container-fluid">
                    <div id="carouselExample" className="carousel slide" data-ride="carousel" data-interval="9000">
                        <div className="carousel-inner row mx-auto" role="listbox">
                            <div className="carousel-item col-sm-3 active">
                                <div className="card-project">
                                    <img className="card-img-top" src={HealthCare} alt="Card cap" />
                                    <div className="card-body-project">
                                        <h5 className="card-title font-weight-bold">Health Care</h5>
                                        <p className="card-text">Ziftex International specializes...</p>
                                    </div>
                                    <div className="card-body-health p-2">
                                        <Link to="/HealthCare" className="text-decoration-none" onClick={scrollToTop}>
                                            <p className="btn-details">
                                                <div className="hidden-sm">SEE MORE <i className="fa fa-arrow-right ml-2" aria-hidden="true"></i></div></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item col-sm-3">
                                <div className="card-project">
                                    <img className="card-img-top" src={Hospitality} alt="Card cap" />
                                    <div className="card-body-project">
                                        <h5 className="card-title font-weight-bold">Hospitality</h5>
                                        <p className="card-text">Ziftex International specializes...</p>
                                    </div>
                                    <div className="card-body-health p-2">
                                        <Link to="/Hospitality" className="text-decoration-none" onClick={scrollToTop}>
                                            <p className="btn-details">
                                                <div className="hidden-sm">SEE MORE <i className="fa fa-arrow-right ml-2" aria-hidden="true"></i></div></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item col-sm-3">
                                <div className="card-project">
                                    <img className="card-img-top" src={Commercial} alt="Card cap" />
                                    <div className="card-body-project">
                                        <h5 className="card-title font-weight-bold">Commercial</h5>
                                        <p className="card-text">Ziftex International specializes...</p>
                                    </div>
                                    <div className="card-body-health p-2">
                                        <Link to="/Commercial" className="text-decoration-none" onClick={scrollToTop}>
                                            <p className="btn-details">
                                                <div className="hidden-sm">SEE MORE <i className="fa fa-arrow-right ml-2" aria-hidden="true"></i></div></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item col-sm-3">
                                <div className="card-project">
                                    <img className="card-img-top" src={BedAndBath} alt="Card cap" />
                                    <div className="card-body-project">
                                        <h5 className="card-title font-weight-bold">Bed & Bath</h5>
                                        <p className="card-text">Ziftex International specializes...</p>
                                    </div>
                                    <div className="card-body-health p-2">
                                        <Link to="/Bedandbath" className="text-decoration-none" onClick={scrollToTop}>
                                            <p className="btn-details">
                                                <div className="hidden-sm">SEE MORE <i className="fa fa-arrow-right ml-2" aria-hidden="true"></i></div></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item col-sm-3">
                                <div className="card-project">
                                    <img className="card-img-top" src={Garments} alt="Card cap" />
                                    <div className="card-body-project">
                                        <h5 className="card-title font-weight-bold">Garments</h5>
                                        <p className="card-text">Ziftex International specializes...</p>
                                    </div>
                                    <div className="card-body-health p-2">
                                        <Link to="/Garments" className="text-decoration-none" onClick={scrollToTop}>
                                            <p className="btn-details">
                                                <div className="hidden-sm">SEE MORE <i className="fa fa-arrow-right ml-2" aria-hidden="true"></i></div></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Helmet>
                <script src="../js/Projects.js" />
            </Helmet>
        </section>
    );
}
