import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Layout from "./components/Layout";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Blog1Content from "./components/Blog1Content";
import Blog2Content from "./components/Blog2Content";
import Blog3Content from "./components/Blog3Content";
import Footer from "./components/Footer";
import Header from "./components/Header";
import FeaturedProducts from "./components/FeaturedProducts";
import "./css/App.css";

import BodySection from "./components/BodySection";
import HealthCare from "./components/HealthCare"
import Commercial from "./components/Commercial";
import Hospitality from "./components/Hospitality";
import Bedandbath from "./components/Bedandbath"
import Garments from "./components/Garments"
import Counter from "./components/Counter";
import BedLinensPillowCovers from "./components/BedLinensPillowCovers";
import TerryTowels from "./components/TerryTowels"
import PatientGowns from "./components/PatientGowns"
import ScrubSuits from "./components/ScrubSuits"
import LabCoats from "./components/LabCoats"
import BathBlankets from "./components/BathBlankets"
import BabyBlankets from "./components/BabyBlankets"
import ThermalBlankets from "./components/ThermalBlankets"
import SurgicalTowels from "./components/SurgicalTowels"
import WaterProofFlatPads from "./components/WaterProofFlatPads"
import CanvasCloth from "./components/CanvasCloth"
import ShopTowels from "./components/ShopTowels"
import YellowDusterandMicroFiber from "./components/YellowDusterandMicroFiber"
import FenderCover from "./components/FenderCover"
import IndustrialApron from "./components/IndustrialApron"
import Towels from "./components/Towels"
import Bathrobes from "./components/Bathrobes"
import Bedsheets from "./components/Bedsheets"
import PillowCovers from "./components/PillowCovers";
import TShirts from "./components/TShirts";
import ShortsandTrousers from "./components/ShortsandTrousers";
import DenimJeans from "./components/DenimJeans";
import DenimJackets from "./components/DenimJackets";
import NightWears from "./components/NightWears";
import Blogs from "./components/Blogs";
import BeddingLinens from "./components/BeddingLinens";
import Pillow from "./components/Pillow";
import PoolandBeachTowels from "./components/PoolandBeachTowels";
import DuvetandPillowCovers from "./components/DuvetandPillowCovers";
import BarMops from "./components/BarMops";
import KitchenTowels from "./components/KitchenTowels";
import LaundryBags from "./components/LaundryBags";
import DuvetComforters from "./components/DuvetComforters";
import SalonandSpaTowels from "./components/SalonandSpaTowels";
import TableCoversandNapkins from "./components/TableCoversandNapkins";
import MattressProtector from "./components/MattressProtector";
import TerryGrillPads from "./components/TerryGrillPads";
import GlassTowels from "./components/GlassTowels";
import BibApron from "./components/BibApron";
import FooterContact from "./components/FooterContact";


class App extends Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<><Layout /><Header /><BodySection /><Counter /><FeaturedProducts /><Blogs/><FooterContact/><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/about-Us"
                        element={<><Layout /><AboutUs /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/contact"
                        element={<><Layout /><Contact /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/blog1"
                        element={<><Layout /><Blog1Content /><FooterContact/><Footer /></>}
                    ></Route>
                      <Route
                        exact
                        path="/blog2"
                        element={<><Layout /><Blog2Content /><FooterContact/><Footer /></>}
                    ></Route>
                      <Route
                        exact
                        path="/blog3"
                        element={<><Layout /><Blog3Content /><FooterContact/><Footer /></>}
                    ></Route>


                    {/* Healthcare start */}

                    <Route
                        exact
                        path="/BedLinensPillowCovers"
                        element={<><Layout /><BedLinensPillowCovers /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/TerryTowels"
                        element={<><Layout /><TerryTowels /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/PatientGowns"
                        element={<><Layout /><PatientGowns /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/ScrubSuits"
                        element={<><Layout /><ScrubSuits /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/LabCoats"
                        element={<><Layout /><LabCoats /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/BathBlankets"
                        element={<><Layout /><BathBlankets /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/BabyBlankets"
                        element={<><Layout /><BabyBlankets /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/ThermalBlankets"
                        element={<><Layout /><ThermalBlankets /><FooterContact/><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/SurgicalTowels"
                        element={<><Layout /><SurgicalTowels /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/WaterProofFlatPads"
                        element={<><Layout /><WaterProofFlatPads/><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/HealthCare"
                        element={<><Layout /><HealthCare /><FooterContact/><Footer /></>}
                    ></Route>

                    {/* Healthcare End */}

                    {/* Hospitality start */}

                    <Route
                        exact
                        path="/BeddingLinens"
                        element={<><Layout /><BeddingLinens /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/Pillow"
                        element={<><Layout /><Pillow /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/PoolandBeachTowels"
                        element={<><Layout /><PoolandBeachTowels /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/DuvetandPillowCovers"
                        element={<><Layout /><DuvetandPillowCovers /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/BarMops"
                        element={<><Layout /><BarMops /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/KitchenTowels"
                        element={<><Layout /><KitchenTowels /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/LaundryBags"
                        element={<><Layout /><LaundryBags /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/DuvetComforters"
                        element={<><Layout /><DuvetComforters /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/SalonandSpaTowels"
                        element={<><Layout /><SalonandSpaTowels /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/TableCoversandNapkins"
                        element={<><Layout /><TableCoversandNapkins /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/MattressProtector"
                        element={<><Layout /><MattressProtector /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/TerryGrillPads"
                        element={<><Layout /><TerryGrillPads /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/GlassTowels"
                        element={<><Layout /><GlassTowels /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/BibApron"
                        element={<><Layout /><BibApron /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/Hospitality"
                        element={<><Layout /><Hospitality /><FooterContact/><Footer /></>}
                    ></Route>

                    {/* Hospitality end */}

                    {/* Commercial start */}
                    
                    <Route
                        exact
                        path="/CanvasCloth"
                        element={<><Layout /><CanvasCloth /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/ShopTowels"
                        element={<><Layout /><ShopTowels /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/YellowDusterandMicroFiber"
                        element={<><Layout /><YellowDusterandMicroFiber /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/FenderCover"
                        element={<><Layout /><FenderCover /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/IndustrialApron"
                        element={<><Layout /><IndustrialApron /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/Commercial"
                        element={<><Layout /><Commercial /><FooterContact/><Footer /></>}
                    ></Route>

                   {/* Commercial End */}

                   {/* Bed&Bath Start */}

                    <Route
                        exact
                        path="/Towels"
                        element={<><Layout /><Towels /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/Bathrobes"
                        element={<><Layout /><Bathrobes /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/Bedsheets"
                        element={<><Layout /><Bedsheets /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/PillowCovers"
                        element={<><Layout /><PillowCovers /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/Bedandbath"
                        element={<><Layout /><Bedandbath /><FooterContact/><Footer /></>}
                    ></Route>

                   {/* Bed&Bath end */}

                   {/* Garments Start */}
                    
                   <Route
                        exact
                        path="/TShirts"
                        element={<><Layout /><TShirts /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/ShortsandTrousers"
                        element={<><Layout /><ShortsandTrousers /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/DenimJeans"
                        element={<><Layout /><DenimJeans /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/DenimJackets"
                        element={<><Layout /><DenimJackets /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/NightWears"
                        element={<><Layout /><NightWears /><Footer /></>}
                    ></Route>
                    <Route
                        exact
                        path="/Garments"
                        element={<><Layout /><Garments /><FooterContact/><Footer /></>}
                    ></Route>

                    {/* Garments end */}

                </Routes>

            </Router>
        );
    }
}

export default App;