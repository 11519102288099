import Bedsheets1 from "../Images/BEDSHEETS1.png";
import Bedsheets2 from "../Images/BEDSHEETS2.png";
import Bedsheets3 from "../Images/bedsheets3.png";
import Bedsheets4 from "../Images/bedsheets4.png";


import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

export default function Bedsheets() {
    return (
        <section className="py-5 mt-5" id="about">
            <Helmet>
                <meta name="keywords" content="Wholesale Bed Sheets, High Quality & Durable, International Trade" />
            </Helmet>

            <div className="container">
    <div className="row mt-5">
        <div className="col-sm-5 d-flex justify-content-center align-items-center items">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="zoom img-border">
                        <img className="object-fit-fill about-img" src={Bedsheets1} alt="Bedsheets" />
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div id="myModal" className="modal">
                        <span className="close">&times;</span>
                        <div className="modal-content">
                            <img id="modalImg" src="" alt="Modal" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mx-auto mt-3">
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={Bedsheets2} alt="Bedsheets" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={Bedsheets3} alt="Bedsheets" />
                        </div>
                        <div className="col-3 gallery-col">
                            <img className="gallery-img" src={Bedsheets4} alt="Bedsheets" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-7 d-flex justify-content-center flex-column mx-auto text-center align-items-center">
            <h3 className="font-weight-bold about-title">Bed Sheets</h3>
            <div className="sectionUnderline mx-auto"></div>
            <p className="mt-4 about-text">Ziftex International bedsheets are crafted from premium materials for a soft and luxurious feel, ensuring a comfortable and restful night's sleep. Elevate your bedroom with our high-quality bedsheets.</p>
            <Link className="product-button mt-2" to="/contact" onClick={scrollToTop}>Inquire Now</Link>
        </div>
    </div>
</div>

            <Helmet>
                <script src="../js/Modal.js" />
            </Helmet>
        </section>
    );
}
