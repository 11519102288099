import React from "react";
import { Link } from "react-router-dom";

function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }
const FooterContact = () => {
    return (
    <section>
    <div className="section scrollspy mb-4" id="contact">
                <div className="section-title-area text-center p-2">
                    <h6 className="subtitle">Contact</h6>
                    <h2 className="font-weight-bold">CONTACT US</h2>
                    <div className="sectionUnderline-feature mt-3 mx-auto"></div>
                </div>
                <div className="container p-3">
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-9">
                            <p className="contact-text text-center">
                            Give us a call for any inquiries or assistance. We look forward to hearing from you
                            </p>
                        </div>

                    </div>
                    <Link className="product-button mt-2 d-flex justify-content-center mx-auto" to="/contact" onClick={scrollToTop}>Inquire Now</Link>

                </div>

            
            </div>
    
</section>)

  };
  
  export default FooterContact;