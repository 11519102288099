import { Helmet } from "react-helmet";




export default function Hospitality() {
    return (

        <section className="py-5 mt-5">
             <Helmet>
                <meta name="keywords" content="Wholesale Hotel Linens, Bulk Bed Sheets, Towels,Bar Mops, Kitchen Towels, Table Linens,Pool Towels, Spa Towels Pakistan Supplier" />
            </Helmet>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="featured-project-wrapper">
                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="section-title-area">
                                        <h6 className="subtitle">FEATURED PRODUCTS</h6>
                                        <h2 className="font-weight-bold">Hospitality</h2>
                                        <div className="sectionUnderline-feature mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="">
                                        <p className="">Established in the year 2000, Ziftex International stands out as a renowned buying and outsourcing agency, proudly managing its operations in Pakistan. With a track record of exporting top-tier products to the U.S.A, Canada, Europe, and the Middle East, we have built a reputation for excellence and reliability in the global market. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="roadmap" className="container-fluid  py-5">
                <div className="container title ">

                    <div className="row mt-5">
                        <div className="col-md-12">
                            <div className="main-timeline">
                              
                                <div className="timeline left wow fadeInLeft">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>BEDDING LINENS</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="timeline right wow fadeInRight">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>PILLOW</h6>

                                        </div>
                                    </div>
                                </div>
                                <div className="timeline left wow fadeInLeft">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>POOL & BEACH TOWELS </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="timeline right wow fadeInRight">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>DUVET & PILLOW COVERS</h6>

                                        </div>
                                    </div>
                                </div>
                                <div className="timeline left wow fadeInLeft">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>BARMOPS </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="timeline right wow fadeInRight">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>KITCHEN TOWELS</h6>

                                        </div>
                                    </div>
                                </div>
                                <div className="timeline left wow fadeInLeft">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>LAUNDRY BAGS </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="timeline right wow fadeInRight">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>DUVET COMFORTERS</h6>

                                        </div>
                                    </div>
                                </div>
                                <div className="timeline left wow fadeInLeft">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6> SALON & SPA TOWELS </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="timeline right wow fadeInRight">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>TABLE COVERS & NAPKINS</h6>

                                        </div>
                                    </div>
                                </div>
                                <div className="timeline left wow fadeInLeft">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>MATTRESS PROTECTOR </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="timeline right wow fadeInRight">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>TERRY GRILL PADS</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="timeline left wow fadeInLeft">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>GLASS TOWELS</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="timeline right wow fadeInRight">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>BIB APRON</h6>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>


    );
}
